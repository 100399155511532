.container {
	margin-top: 20px;
}

.inner {
	display: flex;
}

.formContainer {
	width: 100%;
}

.notifyInput {
	margin-top: 40px !important;
	width: 300px;
}

.sendToContainer {
	margin-left: 100px;
	width: 100%;
}

.receiversList {
	max-height: 700px;
	overflow-y: auto;
	height: 100%;
}

.receiver {
	margin-top: 15px;
}

.receiverPhone {
	margin-left: 10px;
}

.sendButton {
	margin-top: 20px !important;
}