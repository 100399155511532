body {
    background-color: gray;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.container {
    width: 40%;
    height: 100%;
}

.sigContainer {
    width: 100%;
    height: 80%;
    margin: 0 auto;
    background-color: #ede9e8;
}

.sigPad {
    width: 100%;
    height: 100%;
}

.buttons {
    width: 100%;
    cursor:pointer;
    text-align: center;
    padding: auto;
    border-radius: 10px;
    background-color: #DEDEDE;
    height: 30px;
}

.sigImage {
    width: 100%;
    height: 100%;
}